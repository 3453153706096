import { useState, useEffect } from 'react';

//import styling + main js
import './App.scss';

//import components
import Content from './components/Content';
import Header from './components/Header';
import Footer from './components/Footer';


function App() {

  return (
    <div className="app">
      <Header />
        <Content />
      <Footer />
    </div>
  );
}

export default App;