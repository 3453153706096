import { useEffect, useState } from 'react';

// import images (svgs have to imported as a components using 'ReactComponent', then rename!)
import { ReactComponent as KinLeaf }  from '../assets/images/Kin-leaf.svg';

//import components
import HeroSection from './HeroSection/HeroSection';
import ServicesSection from './ServicesSection/ServicesSection';
import AboutUsSection from './AboutUsSection/AboutUsSection';
import ContactUsSection from './ContactUsSection/ContactUsSection';

const Content = (props) => {

    //tableData temp

    //setup table data state
    const [tableData, setTableData] = useState({});

    useEffect(() => {
        // ACCESS DATA FROM GOOGLE SHEET:
        // https://stackoverflow.com/questions/69045745/how-do-i-fetch-google-spreadsheet-data-from-javascript-in-sheets-v4-api-as-json
        // Using GVIZ: https://ai2.metricrat.co.uk/guides/use-gviz-to-get-and-query-google-sheet-data
        let url = 'https://docs.google.com/spreadsheets/d/1plAqv6SmbDuE90OqJK1MfWfEjML6q2PXHSmJFAOFPIw/gviz/tq?tqx=out:json&tq&gid=0';
        fetch(url)
        .then((response) => {
            return response.text(); //keep as 'text' (not '.json()'), since the JSON responce is wrapped in Google query
        })
        .then((data) => {
            data = data.replace('/*O_o*/', '').replace('google.visualization.Query.setResponse(','').slice(0, -2); //remove the Google query wrapper
            data = JSON.parse(data); //convert the string (looks like JSON) to JSON data
            data = data.table.rows; //Each row is an array ('c') of objects. You extract the value of each column with the key 'v'.
            setTableData(data);
        })
        .catch((error) => {
            console.log(error);
        });
        
    }, []);

    return(
        <>
            { Object.keys(tableData).length === 0 ? (
                // (If you map through the state in a lower component, I don't *think* you need to check the object first)

                <div className='kin_loading_wrapper container mx-auto justify-conent-center align-items-center text-center animate_flicker'> 
                    <KinLeaf />
                </div>
            ) : (
                <>
                    <HeroSection tableData={tableData} />
                    <ServicesSection tableData={tableData} />
                    <AboutUsSection tableData={tableData} />
                    <ContactUsSection tableData={tableData} />
                </>
            )}
            
            

        </>
    );
}

export default Content;


// ==========================================================================================



