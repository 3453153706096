//import styling
import './ContactUsSection.scss';

// import images (svgs have to imported as a components using 'ReactComponent', then rename!)
import { ReactComponent as KinLeaf }  from '../../assets/images/Kin-leaf.svg';

const ContactUsSection = (props) => {

    let ContactSubtitle = '';
    let ContactType1 = '';
    let ContactLink1 = '';
    let ContactType2 = '';
    let ContactLink2 = '';
    let ContactType3 = '';
    let ContactLink3 = '';

    props.tableData.map((arrayElement) => {
        if(arrayElement.c[0].v.includes('Contact')){
            ContactSubtitle = arrayElement.c[1].v;
            ContactType1 = arrayElement.c[2].v;
            ContactLink1 = arrayElement.c[3].v;
            ContactType2 = arrayElement.c[4].v;
            ContactLink2 = arrayElement.c[5].v;
            ContactType3 = arrayElement.c[6].v;
            ContactLink3 = arrayElement.c[7].v;
        }
    })

    return(
        <>
        <section id='contact_us_section' className='kin_contact_wrapper container-fluid px-5 pb-5'>
            <div className='kin_contact mx-auto'>
                <h2 className='kin_contact_title'>contact <KinLeaf/></h2>
                <div className='kin_contact_subtitle'>{ContactSubtitle}</div>

                <div className='kin_contact_blocks container mx-auto pt-5 g-0'>
                    <div className='row justify-content-between mx-auto g-0'>
                        <div className='col-12 col-sm-4 text-center'>
                            <a className='mx-0 mx-sm-3 my-3 my-sm-0' href={ContactLink1}>{ContactType1}</a>
                        </div>

                        <div className='col-12 col-sm-4 text-center'>
                            <a className='mx-0 mx-sm-3 my-3 my-sm-0' href={ContactLink2}>{ContactType2}</a>
                        </div>

                        <div className='col-12 col-sm-4 text-center'>
                            <a className='mx-0 mx-sm-3 my-3 my-sm-0' href={ContactLink3}>{ContactType3}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default ContactUsSection;