import { useEffect, useState } from 'react';

//import js, styling + images
import './HeroSection.scss';
import {scrollButtonFunction} from '../../assets/js/global';

//import fall-back image for hero
import backgroundImageFallback from '../../assets/images/kin-hero-image2-min.png';
import {ReactComponent as HeroLogo} from '../../assets/images/Kin-environmental-logo-P1.svg';

const HeroSection = (props) => {

    //controls to pull in DB information about the hero images:
    // let logoImage = '';
    // let heroBackgroundImage = ''; //can be used, but the background image was taking too long to load
    
    // props.tableData.map((arrayElement) => {
    //     if(arrayElement.c[0].v.includes('Hero (Logo, BG image)')){
    //         logoImage = arrayElement.c[1].v;
    //         heroBackgroundImage = arrayElement.c[2].v;
    //     }
    // });

    //the open div element if you use the DB for the hero BG:
    //<div id='kin_hero_background' className='kin_hero container-fluid g-0 mx-auto text-center' style={{backgroundImage: `url(https://kinenvironmental.co.uk/gareths_image_bucket/${heroBackgroundImage})`}} >

    useEffect(()=>{
        //buttonScroll Function
        scrollButtonFunction();
    }, []);

    return(
        <>
            <section id='hero_section' className='kin_hero_wrapper container-fluid g-0 mx-auto'>

                <div id='kin_hero_background' className='kin_hero container-fluid g-0 mx-auto text-center' style={{backgroundImage: `linear-gradient(90deg, rgba(204, 224, 202, 0.2) 0%, rgba(21, 40, 22, 0.3) 20%, rgba(21, 40, 22, 0.3) 80%, rgba(204, 224, 202, 0.2) 100%), url(${backgroundImageFallback})`}} >
                    <div className='align-self-center mx-auto'>
                        <HeroLogo />
                    </div>
                    {/* https://kinenvironmental.co.uk/gareths_image_bucket/ */}
                </div>

                <div className='kin_hero_lower container d-flex mx-auto text-center justify-content-between g-0 px-5 px-lg-3'>
                    <div className='kin_button kin_button_hero' target_div='service_1'>SUSTAINABLE EVENTS</div>
                    <div className='kin_button kin_button_hero' target_div='service_2'>ENVIRONMENTAL CONSULTANCY</div>
                    <div className='kin_button kin_button_hero' target_div='service_3'>CAMPAIGNS</div>
                </div>
            </section>
        </>
    );
}

export default HeroSection;