//import styling + images
import './ServicesSection.scss';

// import images (svgs have to imported as a components using 'ReactComponent', then rename!)
import { ReactComponent as KinLeaf }  from '../../assets/images/Kin-leaf.svg';
import serviceImage1 from '../../assets/images/kin-service-event-image-min.png';
import serviceImage2 from '../../assets/images/kin-consultancy-image-min.png';
import serviceImage3 from '../../assets/images/kin-service-campaign-image-min.png';

const ServicesSection = (props) => {

    let ServiceHeading1 = '';
    let ServiceText1 = '';
    // let ServiceImage1 = '';
    let ServiceHeading2 = '';
    let ServiceText2 = '';
    // let ServiceImage2 = '';
    let ServiceHeading3 = '';
    let ServiceText3 = '';
    // let ServiceImage3 = '';

    props.tableData.map((arrayElement) => {
        if(arrayElement.c[0].v.includes('Service 1')){
            ServiceHeading1 = arrayElement.c[1].v;
            ServiceText1 = arrayElement.c[2].v;
            // ServiceImage1 = arrayElement.c[3].v;
        }

        if(arrayElement.c[0].v.includes('Service 2')){
            ServiceHeading2 = arrayElement.c[1].v;
            ServiceText2 = arrayElement.c[2].v;
            // ServiceImage2 = arrayElement.c[3].v;
        }

        if(arrayElement.c[0].v.includes('Service 3')){
            ServiceHeading3 = arrayElement.c[1].v;
            ServiceText3 = arrayElement.c[2].v;
            // ServiceImage3 = arrayElement.c[3].v;
        }
    })

    return(
        <>
        <section id='service_section' className='kin_service_wrapper container-fluid px-4 px-sm-5'>
            <div className='kin_service mx-auto'>
                <h2 className='kin_service_title'>services <KinLeaf/></h2>
            </div>

            <div id="service_1" className='kin_service_module container g-0 mx-auto pb-5 pb-md-5'>
                <div className='row justify-content-center align-items-top justify-content-between p-4 p-sm-5'>
                    <div className='kin_service_module_image col-12 col-md-4 mb-4 mb-md-0' style={{backgroundImage: `url(${serviceImage1})`}}></div>

                    <div className='kin_service_module_text col-12 col-md-8 ps-0 ps-md-4 ps-lg-5'>
                        <h3>{ServiceHeading1}</h3>
                        <div>{ServiceText1}</div>
                    </div>
                </div>
            </div>

            <div id="service_2" className='kin_service_module container g-0 mx-auto pb-5 pb-md-2 pb-md-5'>
                <div className='row justify-content-center align-items-top justify-content-between p-4 p-sm-5'>
                    <div className='kin_service_module_text col-12 col-md-8 order-2 order-md-1 pe-0 pe-md-4 pe-lg-5'>
                        <h3>{ServiceHeading2}</h3>
                        <div>{ServiceText2}</div>
                    </div>

                    <div className='kin_service_module_image col-12 col-md-4 order-1 order-md-2 mb-4 mb-md-0' style={{backgroundImage: `url(${serviceImage2})`}}></div>
                </div>
            </div>

            <div id="service_3" className='kin_service_module container g-0 mx-auto pb-md-2 pb-md-5'>
                <div className='row justify-content-center align-items-top justify-content-between p-4 p-sm-5'>
                    <div className='kin_service_module_image col-12 col-md-4 mb-4 mb-md-0' style={{backgroundImage: `url(${serviceImage3})`}}></div>

                    <div className='kin_service_module_text col-12 col-md-8 ps-0 ps-md-4 ps-lg-5'>
                        <h3>{ServiceHeading3}</h3>
                        <div>{ServiceText3}</div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default ServicesSection;