//import styling
import './AboutUsSection.scss';

// import images (svgs have to imported as a components using 'ReactComponent', then rename!)
import { ReactComponent as KinLeaf }  from '../../assets/images/Kin-leaf.svg';
import aboutImage from '../../assets/images/kin-about-image-min.png';

const AboutUsSection = (props) => {
    let aboutText = ''
    // let aboutImage = '';

    props.tableData.map((arrayElement) => {
        if(arrayElement.c[0].v.includes('About Us')){
            aboutText = arrayElement.c[1].v;
            // aboutImage = arrayElement.c[2].v;
        }
    })

    return(
        <>
        <section id='about_us_section' className='kin_about_wrapper container-fluid g-0 mx-auto p-5'>
            <div className='kin_about container mx-auto'>
                <div className='row justify-content-center align-items-center justify-content-between'>
                    <div className='kin_about_text col-12 col-md-8 order-2 order-md-1'>
                        <h2 className='kin_about_title pb-4'>about us <KinLeaf/> </h2>
                        <div>{aboutText}</div>
                    </div>

                    <div className='kin_about_image col-12 col-md-4 order-1 order-md-2 mb-4 mb-md-0' style={{backgroundImage: `url(${aboutImage})`}}></div>
                </div>
            </div>
        </section>
        </>
    );
}

export default AboutUsSection;