const Footer = (props) =>{
    return(
        <>
        <footer className='kin_footer_wrapper container-fluid g-0'>
            <div className='kin_footer row mx-auto py-2'>
                <div className='col'>
                    &copy; 2023 Kin Environmental Ltd, Company Number: 14564519
                </div>
            </div>
        </footer>
        </>
    );
}

export default Footer;