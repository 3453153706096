import { useEffect, useState } from 'react';

//import js
import {scrollButtonFunction} from '../assets/js/global';

// import images (svgs have to imported as a components using 'ReactComponent', then rename!)
import { ReactComponent as KinIcon }  from '../assets/images/K-icon.svg';

const Header = (props) => {
    
    useEffect(()=>{
        let navbar = document.querySelector('.kin_header_wrapper');

        let checkScroll = () => {
            let scrollLimit = 75;

            if (window.scrollY >= scrollLimit) {
                navbar.classList.add('kin_header_fadeOut');
            } else {
                navbar.classList.remove('kin_header_fadeOut');
            }
        }
        
        checkScroll();

        window.onscroll = function() {
            checkScroll();
        };

        //buttonScrollFunction
        scrollButtonFunction();
    },[])
    

    return(
        <>
            <header className='kin_header_wrapper kin_header_fade container-fluid g-0 mx-auto text-center'>
                <div className='kin_header row g-0 justify-content-between align-items-center mx-auto p-2'>
                    <div className='kin_header_icon col-2 ps-0 ps-sm-2'>
                        <div class="kin_button" target_div='hero_section'><KinIcon /></div>
                    </div>

                    <nav className='kin_header_nav_wrapper col-10 d-flex justify-content-end pe-3 pe-sm-5'>
                        <div className='kin_header_nav kin_button pe-3 pe-sm-5' target_div='service_section'>services</div>

                        <div className='kin_header_nav kin_button' target_div='about_us_section'>about us</div>

                        <div className='kin_header_nav kin_button ps-3 ps-sm-5' target_div='contact_us_section'>contact</div>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default Header;