//button scroll function
export let scrollButtonFunction = () =>{
    let allKinButtons = Array.from(document.querySelectorAll('.kin_button'));

    allKinButtons.map((element)=>{
        element.addEventListener('click', (event) =>{
            let scrollTarget = event.target.closest('.kin_button').getAttribute('target_div'); //have to use 'closest' for the sake of the logo. (the target of that click is the SVG, not the div that wraps it)
            document.getElementById(scrollTarget).scrollIntoView();
        });
    });
}